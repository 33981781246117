.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;

  .change-password-title {
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 8px;

    .change-password-title {
      font-weight: 600;
      font-size: 18px;
      text-align: left;
      color: var(--ion-color-primary);
      width: 100%;
      display: flex;
      align-items: center;

      .change-pwd-icon {
        padding-right: 4px;
      }
    }
  }

  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 16px;
    width: 100%;

    .input-label {
      display: flex;
      align-items: center;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiInputBase-root .MuiFormControl-root,
  .MuiOutlinedInput-root {
    background: #EAEEF3 !important;
    border-radius: 10px;

  }

  .cp-input .MuiInputBase-input {
    background: #EAEEF3;
    border: 0;
    border-radius: 10px;
    ;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 100%;
    // max-width: 341px;
    height: 48px;
    font-family: 'Jost';
    font-weight: 500;
    font-size: 16px;
    border-radius: 12px;
    line-height: 19px;
    background-color: var(--ion-color-primary);

    @media screen and (max-width: 768px) {
      border-radius: 8px;
    }
    &:hover {
      background-color: var(--ion-color-primary);
    }

    .MuiButton-label {
      color: var(--ion-text-color-contrast);

      &:hover {
        background-color: var(--ion-color-primary);
      }
    }
  }

  .accept-terms-input {
    padding-top: 14px;
    // max-width: 341px;

    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;

      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-text-color);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-text-color) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}

.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}

.user-name,
.pwd-field {
  // max-width: 341px;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {

  .user-name,
  .pwd-field,
  .submit-form-btn {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .user-name,
  .pwd-field,
  .submit-form-btn {
    max-width: 100%;
  }
}